<template>
  <div>
    <sdPageHeader v-if="OrderId" title="Edit Quotation"> </sdPageHeader>
    <sdPageHeader v-else title="Add new Quotation"> </sdPageHeader>
    <Main>
      <a-form
        class="main-form"
        name="sDash_validation-form"
        ref="formRef"
        :rules="rules"
        @finish="onSubmit"
        :model="formState"
        :layout="formState.layout"
      >
        <a-row :gutter="25">
          <a-col :xs="24">
            <sdCards title="Quotation's details" caption="Quotation's details">
              <FormValidationWrap>
                <HorizontalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :span="6" :xs="6">
                      <a-form-item name="salesDate" label="Date">
                        <a-date-picker
                          :disabled="OrderApiloading"
                          v-model:value="formState.salesDate"
                          :style="{ width: '100%' }"
                          :format="dateFormat"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item name="number" label="Number">
                        <a-input
                          class="custom"
                          :disabled="OrderApiloading"
                          v-model:value="formState.number"
                          placeholder="Quotation Number"
                          :style="{ width: '100%' }"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="Partner" name="partenaireId">
                        <a-select
                          v-if="partnerList.length"
                          :disabled="OrderApiloading"
                          v-model:value="formState.partenaireId"
                          placeholder="Please choose Owner"
                        >
                          <a-select-option
                            v-for="(partner, index) in partnerList"
                            :key="index"
                            :value="partner.id"
                          >
                            {{ partner.name }}</a-select-option
                          >
                        </a-select>
                        <a-select
                          v-else
                          :disabled="OrderApiloading"
                          v-model:value="formState.partenaireId"
                          placeholder="Please choose Owner"
                        >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="Status" name="saleStatus">
                        <a-select v-model:value="formState.saleStatus">
                          <a-select-option :value="1"> Draft</a-select-option>
                          <a-select-option :value="2">
                            Confirmed</a-select-option
                          >
                          <a-select-option :value="3">
                            Canceled</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                      <a-form-item label="Note">
                        <a-textarea
                          v-model:value="formState.note"
                          :style="{ width: '100%' }"
                          placeholder="Note ..."
                          name="address"
                          class="sDash_unresizable"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                      <a-form-item label="Private Note">
                        <a-textarea
                          v-model:value="formState.privateNote"
                          :style="{ width: '100%' }"
                          placeholder="Private Note ..."
                          name="address"
                          class="sDash_unresizable"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :offset="18" class="button-container">
                      <sdButton
                        htmlType="onSubmit"
                        key="onSubmit"
                        class="btn-outlined ant-btn-lg client-form-submit"
                        outlined
                        type="primary"
                      >
                        <span>Save</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </HorizontalFormStyleWrap>
              </FormValidationWrap>
            </sdCards>
          </a-col>
        </a-row>
      </a-form>
      <a-row :gutter="25">
        <a-col :xs="24">
          <sdCards title="Products" caption="Products">
            <template #button>
              <sdButton
                v-if="OrderId"
                @click="showAddProductModal"
                type="primary"
              >
                <sdFeatherIcons type="plus" size="14" />
                Add New
              </sdButton>
            </template>
            <a-table :columns="columns" :dataSource="productsData" bordered>
              <template
                v-for="col in ['price', 'quantity']"
                #[col]="{ text, record }"
                :key="col"
              >
                <div>
                  <a-input
                    v-if="editableData[record.key]"
                    v-model:value="editableData[record.key][col]"
                    style="margin: -5px 0"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template #operation="{ record }">
                <div class="editable-row-operations">
                  <span v-if="editableData[record.key]">
                    <a @click="save(record.key)">Save</a>
                    <a-popconfirm
                      title="Sure to cancel?"
                      @confirm="cancel(record.key)"
                    >
                      <a>Cancel</a>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a @click="edit(record.key)">Edit</a>
                    <a @click="deleteFromSale(record)">Delete</a>
                  </span>
                </div>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
    <AddProductModal
      :saleId="OrderId"
      :productsIds="productsIds"
      @hideAddProductModal="hideAddProductModal"
      @SelectedProduct="AddSelectedProduct"
      v-if="showAddProduct && OrderId"
    ></AddProductModal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";

import {
  FormValidationWrap,
  HorizontalFormStyleWrap,
} from "../../forms/overview/Style";
import { RecordViewWrapper } from "../../../view/crud/axios/style";
import { Main, TableWrapper } from "../../styled";
import { useStore } from "vuex";
import {
  computed,
  onMounted,
  reactive,
  ref,
  watchEffect,
  defineAsyncComponent,
  nextTick,
} from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import moment from "moment";
const AddProductModal = defineAsyncComponent(() =>
  import("@/view/product/AddProductModal")
);
const dateFormat = "YYYY/MM/DD";
const todayDate = moment();

const columns = [
  {
    title: "Product",
    dataIndex: "name",
    width: "25%",
    slots: {
      customRender: "name",
    },
  },
  {
    title: "Price",
    dataIndex: "price",
    width: "25%",
    slots: {
      customRender: "price",
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    width: "25%",
    slots: {
      customRender: "quantity",
    },
  },
  {
    title: "Operation",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
const data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const Order = {
  name: "Order",
  components: {
    RecordViewWrapper,
    Main,
    TableWrapper,
    FormValidationWrap,
    HorizontalFormStyleWrap,
    AddProductModal,
  },
  setup() {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const partnerApi = computed(() => state.partnerApi.data);
    const partnerApiLoading = computed(() => state.partnerApi.loading);
    const OrderApi = computed(() => state.orderApi);
    const OrderApiloading = computed(() => state.orderApi.loading);
    const ProductApi = computed(() => state.ProductApi.data);
    const isOrderProductLoading = computed(() => state.productOrderApi.loading);
    const productOrderApi = computed(() => state.productOrderApi.data);
    const ProductAreLoading = computed(() => state.ProductApi.loading);
    const selectedProducts = ref(data);
    const editableData = reactive({});
    var formState = reactive({
      layout: "vertical",
      partenaireId: null,
      number: null,
      saleType: 1,
      saleStatus: 1,
      salesDate: todayDate,
      note: "",
      privateNote: "",
    });

    const formRef = ref();
    const showAddProduct = ref(false);

    const rules = {
      salesDate: [
        {
          type: "object",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      number: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      partenaireId: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("emptyOrder");
      nextTick();
      if (params.id) {
        dispatch("singleOrderGetData", params.id);
      }
      dispatch("partnerInfo");
    });
    watchEffect(() => {
      if (OrderApi.value && OrderApi.value.orderId) {
        dispatch("singleProductOrderGetData", OrderApi.value.orderId);
        formState.id = OrderApi.value.data.id;
        formState.salesDate = moment(OrderApi.value.data.salesDate);
        formState.number = OrderApi.value.data.number;
        formState.saleStatus = OrderApi.value.data.saleStatus;
        formState.partenaireId = OrderApi.value.data.partenaireId;
        formState.note = OrderApi.value.data.note;
        formState.privateNote = OrderApi.value.data.privateNote;
      }
    });

    const productsData = computed(() =>
      productOrderApi.value && productOrderApi.value.length
        ? productOrderApi.value.map((product, key) => {
            const { productId, quantity, price, saleId } = product;
            let name = product.product.name;
            return {
              key: key + 1,
              id: productId,
              productId: productId,
              name: name,
              saleId: saleId,
              quantity,
              price,
            };
          })
        : []
    );
    const productsIds = computed(() =>
      productOrderApi.value && productOrderApi.value.length
        ? productOrderApi.value.map((product) => product.productId)
        : []
    );

    const partnerList = computed(() => {
      return partnerApi.value;
    });
    const OrderId = computed(() => {
      if (OrderApi.value) {
        return OrderApi.value.orderId;
      }
      return null;
    });

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          formState.Status = formState.saleStatus;
          if (!OrderId.value) {
            dispatch("orderSubmitData", {
              formState,
            });
          } else {
            dispatch("orderUpdateData", {
              formState,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    // #region Product
    const showAddProductModal = () => {
      showAddProduct.value = true;
    };
    const hideAddProductModal = (value) => {
      showAddProduct.value = value;
    };
    const AddSelectedProduct = (product) => {
      productOrderApi.value.push(product);
    };
    // #endregion
    // #region table
    const edit = (key) => {
      editableData[key] = cloneDeep(
        productsData.value.filter((item) => key === item.key)[0]
      );
    };

    const save = (key) => {
      let editableobject = productsData.value.filter(
        (item) => key === item.key
      )[0];
      Object.assign(editableobject, editableData[key]);
      dispatch("productOrderUpdateData", editableobject);
      delete editableData[key];
    };

    const deleteFromSale = (product) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("productOrderDelte", product);
      }
      return false;
    };
    const cancel = (key) => {
      delete editableData[key];
    };
    // #endregion

    onBeforeRouteLeave(() => {
      // dispatch('emptyOrder');
      onSubmit();
    });

    return {
      formRef,
      rules,
      onSubmit,
      columns,
      formState,
      partnerApiLoading,
      partnerList,
      partnerApi,
      OrderApi,
      OrderId,
      ProductApi,
      productsData,
      ProductAreLoading,
      OrderApiloading,
      dateFormat,
      showAddProductModal,
      showAddProduct,
      hideAddProductModal,
      selectedProducts,
      AddSelectedProduct,
      editableData,
      editingKey: "",
      edit,
      save,
      deleteFromSale,
      cancel,
      isOrderProductLoading,
      productOrderApi,
      productsIds,
    };
  },
};

export default Order;
</script>
<style lang="scss">
#app {
  .ant-upload {
    width: 100%;
  }
  .custom,
  .ant-calendar-picker-input.ant-input {
    height: 38px;
  }
  .button-container {
    align-self: center;
    .ant-btn {
      height: 38px;
      width: 100%;
    }
  }
  .editable-row-operations a {
    margin-right: 8px;
  }
}
</style>
